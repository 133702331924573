<template>
  <router-view />
</template>

<script>
// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'UpdateView',
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  setup() {
    const { MODULE_NAME } = config()

    return {
      MODULE_NAME,
    }
  },
}
</script>
